import * as React from 'react'
import * as Images from '../components/images'
import {Helmet} from "react-helmet";
import {useStaticQuery, graphql} from 'gatsby'

const StageLogo = () => {
    return (
        <img className="stage-logo" src={Images.wordmark} alt="Rohan Ashik Word mark logo"/>
    )
}


const StageSlogan = () => {
    return (
        <p className="stage-tagline">Favourite word - Focus</p>
    )
}

const Helmat = ({title}) => {
    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    title
                    description
                }
            }
        }
    `)

    var baseTitle = data.site.siteMetadata.title;

    if (title) {
        console.log("i");
        baseTitle = title + " | " + baseTitle;
    } else {
        console.log("no");
    }

    return (
        <Helmet
            title={baseTitle}
            meta={[
                {
                    name: `description`,
                    content: data.site.siteMetadata.description
                }
            ]}
        />
    )
}

export {
    StageSlogan,
    StageLogo,
    Helmat
}

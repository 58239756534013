import * as React from 'react'
import {Link} from 'gatsby'
import {MdClose, MdMenu} from 'react-icons/md';

class Navigation extends React.Component {

    state = {
        revealNavigation: false
    }

    toggleNav = () => {
        this.setState({revealNavigation: !this.state.revealNavigation})
    }

    render() {
        return (
            <div>
                <MdMenu size='25' style={{width:'24px', height:'24px'}} onClick={this.toggleNav} className="togglerIcons togglerIcon"/>
                <nav role="navigation" className={this.state.revealNavigation && 'forceshow offcanvas'}>
                    <ul>
                        <li className="togglerIcons padb10">
                            <MdClose size='25' style={{width:'24px', height:'24px'}} onClick={this.toggleNav}/>
                            <br/><br/>
                        </li>
                        <li><Link activeClassName="active-page" to="/">Home</Link></li>
                        <li><Link activeClassName="active-page" to="/recommendations">Favourites</Link></li>
                        <li><Link activeClassName="active-page" to="/blog">Blog</Link></li>
                        <li><Link activeClassName="active-page" to="/test">Merchandise</Link></li>
                        <li><Link activeClassName="active-page" to="/faq">FAQs</Link></li>
                        <li><Link activeClassName="active-page" to="/quotes">Quotes</Link></li>
                    </ul>
                </nav>
            </div>
        )
    }
}


export default Navigation

